import { Ability } from '@casl/ability'
import { initialAbility } from './initialAbility'
import { Base64 } from 'js-base64'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = localStorage.getItem('userData') && JSON.parse(Base64.decode(localStorage.getItem('userData')))

const existingAbility = userData ? userData.ability : null

export default new Ability(existingAbility || initialAbility)
