import mock from "../mock";
import { paginateArray } from "../utils";

const data = {
  categories: [
    {
      id: 1,
      category: "Спорт",
      fullName: "Спорт",
      description: "Здесь будет описание категории",
      video: require("@src/assets/videos/1.mp4").default,
      company: "Yotz PVT LTD",
      role: "editor",
      username: "gslixby0",
      country: "El Salvador",
      contact: "(479) 232-9151",
      email: "gslixby0@abc.net.au",
      currentPlan: "enterprise",
      involve: "100%",
      status: "Опубликовано",
      avatar: "",
    },
  ],
};

// POST: Add new user
mock.onPost("/apps/categories/add-user").reply((config) => {
  // Get event from post data
  const user = JSON.parse(config.data);

  const { length } = data.videos;
  let lastIndex = 0;
  if (length) {
    lastIndex = data.videos[length - 1].id;
  }
  user.id = lastIndex + 1;

  data.videos.unshift(user);

  return [201, { user }];
});

// DELETE: Deletes User
mock.onDelete("/apps/categories/delete").reply((config) => {
  // Get user id from URL
  let userId = config.id;

  // Convert Id to number
  userId = Number(userId);

  const userIndex = data.users.findIndex((t) => t.id === userId);
  data.users.splice(userIndex, 1);

  return [200];
});
