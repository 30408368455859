import mock from '../mock'
import { paginateArray } from '../utils'
import axios from 'axios'


const data = {
  users: {}
}

// // GET ALL DATA
// mock.onGet(`${process.env.REACT_APP_API}/superadmin/users`).reply(200, data.users)

// // GET USER
// mock.onGet(`${process.env.REACT_APP_API}/superadmin/users/user`).reply(config => {
//   const { id } = config
//   const user = data.users.find(i => i.id === id)
//   return [200, { user }]
// })

// export const getUsers = () => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API}/superadmin/users/superadmin`,
//         {},
//         { headers: new Headers({ 'Content-Type': 'application/json' }) }
//       )
//       resolve(response)
//       console.log(response)
//     } catch (err) {
//       reject(err)
//     }
//   })
// }

// fetch(`${process.env.REACT_APP_API}/superadmin/users/superadmin`, {
//   headers: {
//     method: "GET",
//     body: JSON.stringify(data),
//     mode: 'cors',
//     Accept: 'application/json',
//     Authentication: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Mzc0ODY3NTEuOTk4NzQsInVzZXJfaWQiOjEsInJvbGUiOiJzdXBlcmFkbWluIn0.NAD-1aSx_E77BBW6hkeeJAcqN3AkQvTknM90KEHO_UM'
//   }
// })
//    .then(resp => resp.json())
//    .then(json => console.log(json))