// ** Initial State
const initialState = {
  categories: [],
  selectedCategory: [],
  pending: false,
  search: "",
};

const categories = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CATEGORY_DATA":
      return { ...state, categories: action.data };
    case "SET_SELECTED_CATEGORIES":
      return { ...state, selectedCategory: action.data };
    case "SET_CATEGORY_PENDING":
      return { ...state, pending: action.data };
    case "SET_CATEGORY_SEARCH":
      return { ...state, search: action.data };
    default:
      return { ...state };
  }
};
export default categories;
