import mock from '../mock'
import { paginateArray } from '../utils'

const data = {
  auctions: [
    {
      id: 1,
      date: '28.12.2021',
      lot: 'Лот №1',
      bet: '0.015 USDT',
      budget: '2 475 000 USDT',
      fullName: 'Video #1',
      description: 'Здесь будет описание видео',
      video: require('@src/assets/videos/1.mp4').default,
      company: 'Yotz PVT LTD',
      role: 'editor',
      username: 'gslixby0',
      country: 'El Salvador',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      currentPlan: 'enterprise',
      involve: '100%',
      status: 'На проверке',
      avatar: ''
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/auctions/list/all-data').reply(200, data.auctions)

// POST: Add new user
mock.onPost('/apps/auctions/add-auction').reply(config => {
  // Get event from post data
  const auction = JSON.parse(config.data)

  const { length } = data.auctions
  let lastIndex = 0
  if (length) {
    lastIndex = data.auctions[length - 1].id
  }
  auction.id = lastIndex + 1

  data.auction.unshift(auction)

  return [201, { auction }]
})

// GET Updated DATA
mock.onGet('/api/auctions/list/data').reply(config => {
  const { q = '', perPage = 10, page = 1, role = null, currentPlan = null, status = null } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData = data.auctions.filter(
    auction =>
      (auction.username.toLowerCase().includes(queryLowered) || auction.fullName.toLowerCase().includes(queryLowered)) &&
      auction.role === (role || auction.role) &&
      auction.currentPlan === (currentPlan || auction.currentPlan) &&
      auction.status === (status || auction.status)
  )
  /* eslint-enable  */

  return [
    200,
    {
      auctions: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// GET USER
mock.onGet('/api/auctions/auction').reply(config => {
  const { id } = config
  const auction = data.auctions.find(i => i.id === id)
  return [200, { auction }]
})

// DELETE: Deletes User
mock.onDelete('/apps/auctions/delete').reply(config => {
  // Get user id from URL
  let auctionId = config.id

  // Convert Id to number
  auctionId = Number(auctionId)

  const auctionIndex = data.auctions.findIndex(t => t.id === auctionId)
  data.auctions.splice(auctionIndex, 1)

  return [200]
})
