// ** Initial State
const initialState = {
  optionsCountry: [],
  optionsSortable: [
    {
      label: 'Новые аукционы',
      value: true
    },
    {
      label: 'Старые аукционы',
      value: false
    }
  ],
  optionsTypeAuction: [
    {
      label: 'Все',
      value: 0
    },
    {
      label: 'Активные',
      value: 1
    },
    {
      label: 'Предстоящие',
      value: 2
    },
    {
      label: 'Закрытые',
      value: 3
    }
  ],
  valueSelectedTypeAuction: {
    label: 'Все',
    value: 0
  },
  valueSelectedSort: {
    label: 'Новые аукционы',
    value: true
  },
  valueSelectedCountry: {
    label: 'Все страны',
    value: 0
  },
  auctions: [],
  stateAuctions: [],
  isPending: false
}

const auctions = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_AUCTION_VALUE_TYPE_AUCTION':
      return { ...state, valueSelectedTypeAuction: action.payload }
    case 'SET_AUCTION_VALUE_COUNTRY':
      return { ...state, valueSelectedCountry: action.payload }
    case 'SET_AUCTION_VALUE_SORT':
      return { ...state, valueSelectedSort: action.payload }
    case 'SET_AUCTION_OPTIONS_COUNTRY':
      return { ...state, optionsCountry: action.payload }
    case 'SET_AUCTION_DATA':
      return { ...state, auctions: action.payload }
    case 'SET_AUCTION_STATE_DATA':
      if (state.valueSelectedSort.value) {
        action.payload.sort((a, b) => b.id - a.id)
      } else {
        action.payload.sort((a, b) => a.id - b.id)
      }
      let result
      if (state.valueSelectedCountry.value !== 0) {
        result = action.payload.filter((auction) => auction.country === state.valueSelectedCountry.label)
      } else {
        result = action.payload
      }
      return { ...state, stateAuctions: result }
    case 'SET_AUCTION_IS_PENDING':
      return { ...state, isPending: action.payload }
    default:
      return { ...state }
  }
}
export default auctions
