// ** Initial State
const initialState = {
    country: [],
    regions: [],
    cities: [],
    selectCountryId: null,
    selectRegionId: null
}
  
const country = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_COUNTRY':
        return { ...state, country: action.data }
      case 'GET_REGION':
        return {...state, regions: action.data }
      case 'GET_CITIES':
        return {...state, cities: action.data}
      case 'SELECT_COUNTRY_ID':
        return {...state, selectCountryId: action.data}
      case 'SELECT_REGION_ID':
        return {...state, selectRegionId: action.data}
      default:
        return { ...state }
    }
}

export default country
  