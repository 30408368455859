import mock from '../mock'
import { paginateArray } from '../utils'

const data = {
  requests: [
    {
      id: 1,
      category: 'Red Bull',
      fullName: 'Red Bull',
      description: 'Здесь будет описание проблемы',
      time: '22:04',
      video: require('@src/assets/videos/1.mp4').default,
      company: 'Yotz PVT LTD',
      role: 'editor',
      username: 'gslixby0',
      country: 'El Salvador',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      currentPlan: 'enterprise',
      involve: '100%',
      status: 'На рассмотрении',
      request: 'Запрос',
      avatar: ''
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/requests/list/all-data').reply(200, data.requests)

// POST: Add new user
mock.onPost('/apps/requests/add-user').reply(config => {
  // Get event from post data
  const request = JSON.parse(config.data)

  const { length } = data.requests
  let lastIndex = 0
  if (length) {
    lastIndex = data.requests[length - 1].id
  }
  user.id = lastIndex + 1

  data.requests.unshift(request)

  return [201, { request }]
})

// GET Updated DATA
mock.onGet('/api/requests/list/data').reply(config => {
  const { q = '', perPage = 10, page = 1, role = null, currentPlan = null, status = null } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData = data.requests.filter(
    request =>
      (request.username.toLowerCase().includes(queryLowered) || request.fullName.toLowerCase().includes(queryLowered)) &&
      request.role === (role || request.role) &&
      request.currentPlan === (currentPlan || request.currentPlan) &&
      request.status === (status || request.status)
  )
  /* eslint-enable  */

  return [
    200,
    {
      requests: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// GET USER
mock.onGet('/api/requests/request').reply(config => {
  const { id } = config
  const request = data.requests.find(i => i.id === id)
  return [200, { request }]
})

// DELETE: Deletes User
mock.onDelete('/apps/requests/request').reply(config => {
  // Get user id from URL
  let requestId = config.id

  // Convert Id to number
  requestId = Number(requestId)

  const userIndex = data.requests.findIndex(t => t.id === requestId)
  data.requests.splice(userIndex, 1)

  return [200]
})
