import mock from '../mock'
import { paginateArray } from '../utils'

const data = {
  upcomingAuctions: [
    {
      id: 1,
      date: '28.12.2021',
      lot: 'Лот №2',
      bet: '5.015 USDT',
      budget: '1 475 000 USDT',
      fullName: 'Аукцион #2',
      description: 'Здесь будет описание видео',
      video: require('@src/assets/videos/1.mp4').default,
      company: 'Yotz PVT LTD',
      role: 'editor',
      username: 'gslixby0',
      country: 'El Salvador',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      currentPlan: 'enterprise',
      involve: '100%',
      status: 'На проверке',
      avatar: ''
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/upcoming-auctions/list/all-data').reply(200, data.upcomingAuctions)

// POST: Add new user
mock.onPost('/apps/upcoming-auctions/add-auction').reply(config => {
  // Get event from post data
  const upAuction = JSON.parse(config.data)

  const { length } = data.upcomingAuctions
  let lastIndex = 0
  if (length) {
    lastIndex = data.upcomingAuctions[length - 1].id
  }
  upAuction.id = lastIndex + 1

  data.upAuction.unshift(upAuction)

  return [201, { upAuction }]
})

// GET Updated DATA
mock.onGet('/api/upcoming-auctions/list/data').reply(config => {
  const { q = '', perPage = 10, page = 1, role = null, currentPlan = null, status = null } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData = data.upcomingAuctions.filter(
    upAuction =>
      (upAuction.username.toLowerCase().includes(queryLowered) || upAuction.fullName.toLowerCase().includes(queryLowered)) &&
      upAuction.role === (role || upAuction.role) &&
      upAuction.currentPlan === (currentPlan || upAuction.currentPlan) &&
      upAuction.status === (status || upAuction.status)
  )
  /* eslint-enable  */

  return [
    200,
    {
      upcomingAuctions: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// GET USER
mock.onGet('/api/upcoming-auctions/auction').reply(config => {
  const { id } = config
  const upAuction = data.upcomingAuctions.find(i => i.id === id)
  return [200, { upAuction }]
})

// DELETE: Deletes User
mock.onDelete('/apps/upcoming-auctions/delete').reply(config => {
  // Get user id from URL
  let upAuctionId = config.id

  // Convert Id to number
  upAuctionId = Number(upAuctionId)

  const auctionIndex = data.upcomingAuctions.findIndex(t => t.id === upAuctionId)
  data.upcomingAuctions.splice(auctionIndex, 1)

  return [200]
})
