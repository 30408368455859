// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import users from '@src/views/apps/user/store/reducer'
import videos from '@src/views/apps/video/store/reducer'
import categories from '@src/views/apps/video-category/store/reducer'
import auctions from '@src/views/apps/auctions/store/reducer'
import lots from '@src/views/apps/lots/store/reducer'
// import upcomingAuctions from '@src/views/apps/upcoming-auction/store/reducer'
// import requests from '@src/views/apps/requests/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import calendar from '@src/views/apps/calendar/store/reducer'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'
import country from '../../views/apps/regions/store/reducer'
const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  videos,
  categories,
  auctions,
  lots,
  // requests,
  // upcomingAuctions,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  country
})

export default rootReducer
