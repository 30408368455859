// ** Initial State

import moment from "moment";

const initialState = {
  pending: false,
  users: [],
  totalRows: null,
  selectedUsers: [],
  params: {
    email: '',
    name: '',
    phone: '',
    role: '',
    active: '',
    country: '',
    region: '',
    city: '',
    started_at: '2000-01-01T00:00:00Z',
    ended_at: new Date().toISOString(),
    limit: 10,
    offset: 0,
    id: 0
  },
  search: "",
  countries: [],
  regions: [],
  cities: [],
  typeSearch: 'name',
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USER_PENDING":
      return {...state, pending: action.data }
    case "GET_SELECTED_USERS":
      return {...state, selectedUsers: action.data }
    case "SET_USERS":
      return {...state, users: action.data }
    case "SET_USER_TYPE_SEARCH":
      return {...state, typeSearch: action.data }
    case "SET_USER_PARAMS":
      return {...state, params: action.data }
    case "GET_USER_SEARCH":
      return {...state, search: action.data }
    case "SET_USER_TOTAL_ROWS":
      return {...state, totalRows: action.data }
    case "SET_USER_COUNTRIES":
      return {...state, countries: action.data }
    case "SET_USER_REGIONS":
      return {...state, regions: action.data }
    case "SET_USER_CITIES":
      return {...state, cities: action.data }
    default:
      return { ...state };
  }
};
export default users;
