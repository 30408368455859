// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  filterShowColumns: {
    id: true,
    name: true,
    country: true,
    category: true,
    company: true,
    video: true,
    date: true
  },
  selectedVideo: null
}

const videos = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_VIDEO_DATA':
      return { ...state, allData: action.data.sort((a, b) => a.id - b.id) }
    case 'VIDEO_FILTERSHOWCOLUMNS':
      return { ...state, filterShowColumns: action.filterShowColumns }
    case 'GET_VIDEO_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_VIDEO':
      return { ...state, selectedVideo: action.selectedVideo }
    case 'ADD_USER':
      return { ...state }
    case 'DELETE_USER':
      return { ...state }
    default:
      return { ...state }
  }
}
export default videos
